<template>
	<div class="as-autocomplete">
		<div style="height: 28px; margin-left: 20px;">
			<el-button @click="hide" style="height: 100%;padding: 5px 20px 2px;">筛选</el-button>
		</div>
		<div class="as-autocomplete-box" v-show="shows">
			<div class="as-autocomplete-list">
				<el-date-picker v-model="dataFrom.classTime" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" @change="datachange">
				</el-date-picker>
				<div class="margin-top-lg flex justify-content-between padding-bottom-sm border-bottom-grey">
					<el-select v-model="dataFrom.arrivalStatus" filterable placeholder="到校状态" class="margin-right" @change="arrivalChange">
						<el-option label="请假" value="请假" />
						<el-option label="未记录" value="未记录" />
					</el-select>

					<el-select v-model="dataFrom.makeUpStatus" filterable placeholder="补课状态" @change="makeUpChange">
						<el-option label="已补课" value="已补课" />
						<el-option label="已安排" value="已安排" />
						<el-option label="未安排" value="未安排" />
					</el-select>
				</div>

				<div class="padding-top-sm ">
					<el-select v-model="dataFrom.campus" filterable placeholder="校区" @change="campusChange">
						<el-option label="全部校区" value="全部校区" />
						<el-option label="驿都城校区" value="驿都城校区" />
					</el-select>
				</div>
				<div class="padding-top-sm ">
					<el-select v-model="dataFrom.className" multiple filterable allow-create default-first-option
						placeholder="课程" @change="classNameChange">
						<el-option v-for="item in campusList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				shows: false,
				num: 0,
				dataFrom:{
					classTime: '', //上课时间
					arrivalStatus: '', //到课状态
					makeUpStatus: '', //补课状态
					campus: '', //校区
					className: [], //课程明细
				},
				campusList: [{
						value: "驿都城一年级1V1 (三星级) 课程",
						label: "驿都城一年级1V1 (三星级) 课程"
					},
					{
						value: "驿都城二年级1V1 (三星级) 课程",
						label: "驿都城二年级1V1 (三星级) 课程"
					},
					{
						value: "驿都城三年级1V1 (三星级) 课程",
						label: "驿都城三年级1V1 (三星级) 课程"
					},
					{
						value: "驿都城四年级1V1 (三星级) 课程",
						label: "驿都城四年级1V1 (三星级) 课程"
					},
					{
						value: "驿都城五年级1V1 (三星级) 课程",
						label: "驿都城五年级1V1 (三星级) 课程"
					},

				],
			};
		},
	
		methods: {
			hide() {
				this.num++
				if (this.num % 2 == 0) {
					this.shows = false
				} else {
					this.shows = true
				}
			},
			datachange(item){
				this.dataFrom.classTime=item
				 this.$emit('selectChange',this.dataFrom);
			},
			arrivalChange(item){
				this.dataFrom.arrivalStatus=item
				 this.$emit('selectChange',this.dataFrom);
			},
			makeUpChange(item){
				this.dataFrom.makeUpStatus=item
				 this.$emit('selectChange',this.dataFrom);
			},
			campusChange(item){
				this.dataFrom.campus=item
				 this.$emit('selectChange',this.dataFrom);
			},
			classNameChange(item){
				this.dataFrom.className=item
				 this.$emit('selectChange',this.dataFrom);
			}
			//点击某一项的事件
		},
	};
</script>
<style lang="scss" scoped>
	.as-autocomplete {
		display: inline-block;

		.as-autocomplete-title {
			width: 100%;
		}

		input:focus+.as-autocomplete-box {
			display: block;
		}

		.as-autocomplete-box:hover {
			display: block;
		}

		.as-autocomplete-box {
			position: absolute;
			top: 50px;
			right: -16px;
			z-index: 999;
			background-color: #fff;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		}

		.as-autocomplete-list {
			padding: 10px;
			max-height: 200px;
			overflow-y: auto;
		}
	}
	
</style>
