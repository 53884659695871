<template>
  <div class="width-100" style="padding: 20px">
    <div class="flex aligin-items-center bg-z padding-tb" style="padding-left:10px">
      <span class="padding-right">
        <span class="class-tag">班</span>
        <span v-if="Class_data.class_name" class="margin-left-sm padding-right top_text">{{ Class_data.class_name
          }}</span>
        <span v-else class="margin-left-sm top_text"> 暂无班级</span>
      </span>
      <span v-if="Row_data && Row_data.school_name" class="padding-right">
        <span class="school-tag">校</span>
        <span v-if="Row_data.school_name" class="margin-left-sm top_text">
          {{ Row_data.school_name }}</span>
        <span v-else class="margin-left-sm top_text"> 暂无学校名称</span>
      </span>
      <span v-else class="padding-right">
        <span class="school-tag">校</span>
        <span v-if="Class_data.school_name" class="margin-left-sm top_text">
          {{ Class_data.school_name }}</span>
        <span v-else class="margin-left-sm top_text"> 暂无学校名称</span>
      </span>
      <span v-if="Class_data.curriculum && Class_data.curriculum.curriculum_name" class="padding-right">
        <span class="lesson-tag">课</span>
        <span v-if="Class_data.curriculum && Class_data.curriculum.curriculum_name" class="margin-left-sm top_text">{{
          Class_data.curriculum.curriculum_name }}</span>

        <span v-else class="margin-left-sm top_text"> 暂无课程信息</span>
      </span>
      <span v-else class="padding-right">
        <span class="lesson-tag">课</span>
        <span v-if="Row_data&&Row_data.curriculum_name" class="margin-left-sm top_text">{{
          Row_data.curriculum_name
          }}</span>

        <span v-else class="margin-left-sm top_text"> 暂无课程信息</span>
      </span>
    </div>
    <div class="padding-tb"><span style="font-size: 20px">上课信息</span></div>
    <el-form v-if="!edittype" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
      class="demo-ruleForm flex flex-wrap-wrap">
      <ul style="width: 100%" class="flex flex-wrap-wrap">
        <li style="width: 25%">
          <el-form-item label="上课日期:" prop="class_begin_date">
            <span v-if="Row_data && Row_data.class_begin_date">{{
              Row_data.class_begin_date.split("T")[0]
              }}</span>
            <el-date-picker v-else type="date" :picker-options="pickerOptions0" placeholder="选择日期:"
              format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="ruleForm.class_begin_date"></el-date-picker>
          </el-form-item>
        </li>
        <li style="width: 25%">
          <el-form-item label="上课教师:" prop="arrange_teacher">
            <el-select v-if="!edittype" v-model="ruleForm.arrange_teacher" placeholder="请选择上课教师">
              <el-option v-for="item in ArrangeTeachers_List" :key="item.value" :label="item.full_name"
                :value="item.id"></el-option>
            </el-select>
            <span v-else>{{ Row_data.arrange_teacher_name }}</span>
          </el-form-item>
        </li>
        <li style="width: 20%">
          <el-form-item label="教师课时:" prop="teacher_class_hour">
            <!-- <el-input
          type="number"
          style="width: 100px"
          v-model.number="ruleForm.name"
        ></el-input> -->
            <el-input-number v-if="!edittype" v-model="ruleForm.teacher_class_hour" controls-position="right" :min="1"
              :max="24"></el-input-number>
            <span>{{ Row_data.teacher_class_hour }}</span>
          </el-form-item>
        </li>
        <li style="width: 30%">
          <el-form-item label="上课时段:" prop="Class_Hours">
            <div v-if="Row_data&& Row_data.class_begin_time_start">
              <span v-if="Row_data && Row_data.class_begin_time_start">{{
                Row_data.class_begin_time_start
                }}</span>
              <span v-if="Row_data && Row_data.class_begin_time_end">-{{ Row_data.class_begin_time_end }}</span>
            </div>
            <el-time-picker v-else is-range v-model="ruleForm.Class_Hours" range-separator="至"
              @change="Time_handleChange" start format="HH:mm" value-format="HH:mm" start-placeholder="开始时间"
              end-placeholder="结束时间" placeholder="选择时间范围">
            </el-time-picker>
          </el-form-item>
        </li>
        <li style="width: 25%">
          <el-form-item label="助教:">
            <el-select v-if="!edittype" v-model="ruleForm.arrange_assistant" placeholder="请选择助教">
              <el-option v-for="item in ArrangeTeachers_List" :key="item.value" :label="item.full_name"
                :value="item.id"></el-option>
            </el-select>
            <span v-else>{{ Row_data.arrange_assistant_name }}</span>
          </el-form-item>
        </li>
        <li style="width: 25%">
          <el-form-item label="科目:">
            <el-select v-model="ruleForm.arrange_subject" placeholder="请选择科目">
              <el-option v-for="(item, index) in Subject_Type" :key="index" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </li>
        <li style="width: 25%">
          <el-form-item label="备注:">
            <el-input type="textarea" v-if="!edittype" v-model="ruleForm.remark"></el-input>
            <span v-else>{{ Row_data.remark }}</span>
          </el-form-item>
        </li>
      </ul>
    </el-form>
    <ul v-else style="width: 100%" class="flex flex-wrap-wrap">
      <li style="width: 25%">
        <span>上课日期：</span><span v-if="Row_data && Row_data.class_begin_date">{{
          Row_data.class_begin_date.split("T")[0]
          }}</span>
      </li>
      <li style="width: 25%">
        <span>上课教师：</span><span v-if="Row_data && Row_data.arrange_teacher_name">{{
          Row_data.arrange_teacher_name
          }}</span>
      </li>
      <li style="width: 25%">
        <span>教师课时：</span><span v-if="Class_data && Class_data.teacher_time">{{
          Class_data.teacher_time
          }}</span>
      </li>
      <li style="width: 25%">
        <span>上课时段：</span>
        <span v-if="Row_data && Row_data.class_begin_time_start">{{
          Row_data.class_begin_time_start
          }}</span>
        <span v-if="Row_data && Row_data.class_begin_time_end">-{{ Row_data.class_begin_time_end }}</span>
      </li>
      <li style="width: 25%" class="margin-top">
        <span>助教：</span>
        <span v-if="Row_data && Row_data.arrange_assistant_name">{{
          Row_data.arrange_assistant_name
          }}</span>
      </li>
      <li style="width: 25%" class="margin-top">
        <span> 科目：</span>
        <span v-if="Row_data && Row_data.arrange_subject_name">{{
          Row_data.arrange_subject_name
          }}</span>
      </li>
      <li style="width: 25%" class="margin-top">
        <span>备注：</span><span v-if="Row_data && Row_data.remark">{{ Row_data.remark }}</span>
      </li>
    </ul>
    <div class="padding-tb">
      <span style="font-size: 20px">学员记上课</span>
    </div>
    <!-- <div class="padding-tb-sm">
      <el-button type="primary">成功按钮</el-button>
      <el-button>主要按钮</el-button>
      <el-button>主要按钮</el-button>
    </div> -->
    <div v-if="!edittype">
      <div class="padding-tb-sm">
        <span>共计{{ outer_data.total_count }}名学员，到课{{
          outer_data.daoke
          }}人，请假{{ outer_data.qingjia }}人，旷课{{
          outer_data.kuangke
          }}人，未记录{{ outer_data.jilv }}人。</span>
      </div>
      <el-table :data="datalist">
        <el-table-column align="center" prop="attend_class_timeinterval_start" label="学员头像">
          <template slot-scope="scope">
            <el-avatar :size="50">
              <img width="50" height="50" v-if="scope.row.avatar" :src="scope.row.avatar | previewImage" />
              <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="FullName" label="学员姓名"></el-table-column>
        <el-table-column align="center" prop="remaining_class_hours" label="剩余课时/天数"></el-table-column>
        <el-table-column align="center" prop="valid_hours" label="有效课时"></el-table-column>
        <el-table-column align="center" prop="leave_count" label="请假次数"></el-table-column>
        <el-table-column align="center" prop="school_name" label="本次扣课时">
          <template slot-scope="scope">
            <el-input-number size="mini" v-model="scope.row.valid_hours" controls-position="right"
              @change="handleChange" :min="1" :max="1000"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column width="200" align="center" prop="class_room" label="到课状态">
          <template slot-scope="scope">
            <el-radio-group size="mini" v-model="scope.row.status">
              <el-radio-button label="到课"></el-radio-button>
              <el-radio-button label="请假"></el-radio-button>
              <el-radio-button label="旷课"></el-radio-button>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="class_room" label="到课类型">
          <template slot-scope="scope">
            <el-checkbox v-if="scope.row.status == '到课'" v-model="scope.row.class_type">迟到</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="school" label="操作">
          <template slot-scope="scope">
            <el-button @click="delstudent(scope.$index, datalist)" type="text" size="small">
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- //编辑 -->
    <div v-else>
      <div class="padding-tb-sm">
        <span>共计{{ outer_data.total_count }}名学员，到课{{
          outer_data.daoke
          }}人，请假{{ outer_data.qingjia }}人，旷课{{
          outer_data.kuangke
          }}人，未记录{{ outer_data.jilv }}人。</span>
      </div>
      <el-table :data="datalist">
        <el-table-column align="center" label="学员头像">
          <template slot-scope="scope">
            <el-avatar :size="50">
              <img width="50" height="50" v-if="scope.row.avatar" :src="scope.row.avatar | previewImage" />
              <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="FullName" label="学员姓名"></el-table-column>
        <el-table-column align="center" prop="remaining_class_hours" label="剩余课时/天数"></el-table-column>
        <el-table-column align="center" prop="valid_hours" label="有效课时"></el-table-column>
        <el-table-column align="center" prop="leave_count" label="请假次数"></el-table-column>
        <el-table-column align="center" prop="school_name" label="本次扣课时">
          <template slot-scope="scope">
            <el-input-number v-if="currentEditModel.id === scope.row.id" size="mini"
              v-model="currentEditModel.valid_hours" controls-position="right" @change="handleChange" :min="1"
              :max="1000"></el-input-number>

            <span v-else>{{ scope.row.valid_hours }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200" align="center" prop="class_room" label="到课状态">
          <template slot-scope="scope">
            <el-radio-group v-if="currentEditModel.id === scope.row.id" size="mini" v-model="scope.row.status">
              <el-radio-button label="到课"></el-radio-button>
              <el-radio-button label="请假"></el-radio-button>
              <el-radio-button label="旷课"></el-radio-button>
            </el-radio-group>
            <span v-else>{{ scope.row.status }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="class_room" label="到课类型">
          <template slot-scope="scope">
            <el-checkbox v-if="
                scope.row.status == '到课' &&
                currentEditModel.id === scope.row.id
              " v-model="scope.row.class_type">迟到</el-checkbox>
            <span v-if="
                scope.row.status != '到课' &&
                currentEditModel.id === scope.row.id
              ">{{ scope.row.class_type }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="school" label="操作">
          <template slot-scope="scope">
            <el-button v-if="currentEditModel.id === scope.row.id" @click="editstudent(scope.$index, scope.row)"
              type="text" size="small">
              保存
            </el-button>
            <el-button v-if="currentEditModel.id === scope.row.id" @click="cancelEdit(scope.$index, scope.row)"
              type="text" size="small">
              取消
            </el-button>
            <el-button v-else @click="edit(scope.$index, scope.row)" type="text" size="small">
              编辑
            </el-button>

            <el-button v-if="currentEditModel.id != scope.row.id" @click="dels(scope.$index, scope.row)" type="text"
              size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="padding">
      <el-button type="primary" v-if="datalist && datalist.length && !edittype" @click="submitForm('ruleForm')">保存
      </el-button>
      <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
    </div>
  </div>
</template>
<script>
  import AsList from "@/components/as-list";
  import Asfilter from "@/components/as-filter";
  import { course, starList, withWithout } from "@/config/index";
  export default {
    components: {
      AsList,
      Asfilter,
    },

    data() {
      return {
        outer_data: {},
        pickerOptions0: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6;
          },
        },
        enddate: "",
        class_begin_date: "",
        Rowid: null,
        edittype: false,
        teacherQueryData: {
          post_type: [8],
          KeyWord: "",
          PageSize: 20,
          PageIndex: 1,
        },
        Subject_Type: [],
        ArrangeTeachers_List: [],
        assistant_List: [],
        Class_data: {},
        Row_data: [],
        datalist: [],
        datalist_copy: [],
        currentEditModel: {},
        SubjectQuery: {
          module: "SubjectType",
          parentId: 10,
        }, //获取科目
        listQueryStudent: {
          begin_date: "",
          arrange_id: 0,

          KeyWord: "",
          PageIndex: 1,
          PageSize: 10,
        },
        radio2: "",
        ClassId: "",
        ruleForm: {
          arrange_subject: null, //科目
          arrange_classes: "",
          arrange_assistant: null, //助教
          Class_Hours: "", //上课时段
          teacher_class_hour: "",
          class_begin_time_start: "", //上课时段开始
          class_begin_time_end: "", //上课时段结束
          value2: "",
          arrange_teacher: null, //上课教师
          class_begin_date: "",
          remark: "",
        },
        rules: {
          teacher_class_hour: [
            { required: true, message: "请选择课时", trigger: "blur" },
          ],
          // Class_Hours: [
          //   { required: false, message: "请选择上课时段", trigger: "blur" },
          // ],
          arrange_teacher: [
            { required: true, message: "请选择上课教师", trigger: "change" },
          ],
          class_begin_date: [
            {
              required: true,
              message: "请选择日期",
              trigger: "change",
            },
          ],
          date2: [
            {
              required: true,
              message: "请选择时间",
              trigger: "change",
            },
          ],
          desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
        },
      };
    },
    created() {
      var date = new Date();
      this.ruleForm.class_begin_date = date.toLocaleDateString();

      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      this.enddate = yy + "-" + mm + "-" + dd;
      this.ruleForm.class_begin_date = this.enddate;

      this.class_begin_date = this.$route.query.date;
      this.ClassId = this.$route.query.classid; //班级id
      if (this.$route.query.id) {
        this.Rowid = this.$route.query.id; //排课记录id
      } else {
        this.Rowid = null;
      }

      if (this.$route.query.type == "edit") {
        this.edittype = true;
      }
      this.listQueryStudent.classes_id = this.ClassId;
      this.ruleForm.arrange_classes = this.ClassId;
      this.get_student();
    },
    methods: {
      delstudent(index, row) {
        if (this.datalist.length == 1) {
          this.$message.error("不可移除所有学员");
        } else {
          this.$confirm("确定删除上课记录吗?", "删除记录", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.remove_(index, row);
            })
            .catch(() => {
              // this.$message({
              //   type: 'info',
              //   message: '已取消删除'
              // });
            });
        }
      },
      dels(index, row) {
        this.$confirm("确定删除上课记录吗?", "删除记录", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.API.CurriculumAttendChangestatus(row.id).then((res) => {
              if (res.success) {
                this.$message({
                  message: "删除成功！",
                  type: "success",
                });
                this.get_student();
              } else {
                this.$message({
                  message: "删除失败！",
                  type: "warning",
                });
              }
            });
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });
      },
      edit(index, row) {
        this.currentEditModel = row;
      },
      cancelEdit(index, row) {
        this.currentEditModel = {};
        for (let index = 0; index < this.datalist_copy.length; index++) {
          if (this.datalist_copy[index].id == row.id) {
            row = this.datalist_copy[index];
          }
        }
      },
      editstudent(index, row) {
        var class_arrival = 0;
        var class_type = 0;
        if (row.status == "到课") {
          class_arrival = 1;
        } else if (row.status == "请假") {
          class_arrival = 2;
        } else if (row.status == "旷课") {
          class_arrival = 3;
        }
        if (row.status == "到课" && row.class_type) {
          class_type = 1;
        } else {
          class_type = 0;
        }
        var model = {
          record_id: this.Rowid,
          data: [
            {
              student_hours: row.valid_hours,
              student_id: row.id,
              student_name: row.FullName,
              record_id: row.recorded_id,
              class_arrival: class_arrival,
              class_type: class_type,
            },
          ],
        };
        debugger;
        this.API.CurriculumAttendEditAttendClassInfo(model).then((res) => {
          this.$message({
            showClose: true,
            message: res.data,
            type: "success",
          });
          this.$router.push({
            path: "/educationCenter/remembertoClass",
          });
        });
      },
      remove_(index, row) {
        row.splice(index, 1);
      },
      Time_handleChange(value) {
        this.ruleForm.class_begin_time_start = value[0];
        this.ruleForm.class_begin_time_end = value[1];
      },
      handleChange(value) { },
      get_student() {
        if (this.Row_data.class_begin_date) {
          this.listQueryStudent.begin_date = this.Row_data.class_begin_date.split(
            "T"
          )[0];
        } else {
          this.listQueryStudent.begin_date = this.enddate;
        }
        if (this.Rowid) {
          this.listQueryStudent.arrange_id = this.Rowid;
        }
        // this.Rowid
        this.API.GetClassesStudents(this.listQueryStudent).then((res) => {
          if (this.edittype) {

          }
          this.datalist = res.data.rows;
          this.outer_data = res.data.outer_data;
          this.datalist_copy = res.data.rows;
        });
        // 
        this.API.ClassesDetail(this.ClassId).then((res) => {
          this.Class_data = res;

          if (this.Class_data && this.Class_data.teacher_time) {
            this.ruleForm.Class_Hours = this.Class_data.teacher_time;
          }
        });
        //查询单个排课信息CurriculumAttendDetail
        if (this.Rowid && this.edittype) {
          this.API.CurriculumAttendDetail(this.Rowid).then((res) => {
            this.Row_data = res.data;
            if (this.Row_data && this.Row_data.arrange_teacher) {
              this.ruleForm.arrange_teacher = this.Row_data.arrange_teacher;
            }
            if (this.Row_data && this.Row_data.arrange_assistant) {
              this.ruleForm.arrange_assistant = this.Row_data.arrange_assistant;
            }
            if (this.Row_data && this.Row_data.arrange_subject) {
              this.ruleForm.arrange_subject = this.Row_data.arrange_subject;
            }
            if (this.Row_data && this.Row_data.class_begin_date) {
              this.ruleForm.class_begin_date = this.Row_data.class_begin_date;
            }
            if (this.Row_data && this.Row_data.class_begin_time_start) {
              this.ruleForm.class_begin_time_start = this.Row_data.class_begin_time_start;
            }
            if (this.Row_data && this.Row_data.class_begin_time_end) {
              this.ruleForm.class_begin_time_end = this.Row_data.class_begin_time_end;
            }
            console.log(this.Row_data)
          });
        } else {
          if (this.Rowid) {
            this.API.CurriculumarrangerecordsDetail(this.Rowid).then((res) => {
              this.Row_data = res.data;
              if (this.Row_data && this.Row_data.arrange_teacher) {
                this.ruleForm.arrange_teacher = this.Row_data.arrange_teacher;
              }
              if (this.Row_data && this.Row_data.arrange_assistant) {
                this.ruleForm.arrange_assistant = this.Row_data.arrange_assistant;
              }
              if (this.Row_data && this.Row_data.arrange_subject) {
                this.ruleForm.arrange_subject = this.Row_data.arrange_subject;
              }
              if (this.Row_data && this.Row_data.class_begin_date) {
                this.ruleForm.class_begin_date = this.Row_data.class_begin_date;
              }
              if (this.Row_data && this.Row_data.class_begin_time_start) {
                this.ruleForm.class_begin_time_start = this.Row_data.class_begin_time_start;
              }
              if (this.Row_data && this.Row_data.class_begin_time_end) {
                this.ruleForm.class_begin_time_end = this.Row_data.class_begin_time_end;
              }
              console.log(this.ruleForm)
            });
          }
        }
        var ArrangeTeachers = {
          // AccountType: [5],
          post_tag: ["教师"],
          PageIndex: 1,
          PageSize: 100,
        };
        this.API.accountQuery(ArrangeTeachers).then((res) => {
          this.ArrangeTeachers_List = res.data.rows;
        });

        // this.API.accountQuery(this.teacherQueryData).then((res) => {
        //   this.ArrangeTeachers_List = res.data.rows;
        // });
        //查询助教
        var model_assistant = {
          AccountType: [11],
          KeyWord: "",
          PageSize: 20,
          PageIndex: 1,
        };
        this.API.accountQuery(model_assistant).then((res) => {
          this.assistant_List = res.data.rows;
        });

        this.$Dictionary.GetDictionaryTree(this.SubjectQuery).then((res) => {
          this.Subject_Type = res.data;
        });
      },
      add_attendClassForNotArrange() {
        var model = {
          class_begin_date: this.ruleForm.class_begin_date,
          teacher_class_hour: this.ruleForm.teacher_class_hour,
          arrange_classes: this.ruleForm.arrange_classes,
          class_begin_time_start: this.ruleForm.class_begin_time_start,
          class_begin_time_end: this.ruleForm.class_begin_time_end,
          arrange_teacher: this.ruleForm.arrange_teacher,
          arrange_assistant: this.ruleForm.arrange_assistant,
          arrange_subject: this.ruleForm.arrange_subject,
          remark: this.ruleForm.remark,
          data: [],
        };
        var data = [];
        for (let index = 0; index < this.datalist.length; index++) {
          var class_arrival = 0;
          var class_type = 0;
          if (this.datalist[index].status == "到课") {
            class_arrival = 1;
          } else if (this.datalist[index].status == "请假") {
            class_arrival = 2;
          } else if (this.datalist[index].status == "旷课") {
            class_arrival = 3;
          }
          if (
            this.datalist[index].status == "到课" &&
            this.datalist[index].class_type
          ) {
            class_type = 1;
          } else {
            class_type = 0;
          }
          data.push({
            student_id: this.datalist[index].id,
            use_hours: this.datalist[index].valid_hours,
            id: 0,
            class_arrival: class_arrival,
            class_type: class_type,
          });
        }
        model.data = data;
        this.API.attendClassForNotArrange(model).then((res) => {
          this.$message({
            showClose: true,
            message: res.data,
            type: "success",
          });
          this.$router.push({
            path: "/educationCenter/remembertoClass",
          });
        });
      },
      add_attendClass() {
        debugger;
        var model = {
          arrange_currculum_id: this.Row_data.id,
          class_begin_date: this.ruleForm.class_begin_date,
          teacher_hours: this.ruleForm.teacher_class_hour,
          clasess_id: this.ruleForm.arrange_classes,
          class_begin_time_start: this.ruleForm.class_begin_time_start,
          class_begin_time_end: this.ruleForm.class_begin_time_end,
          arrange_teacher: this.ruleForm.arrange_teacher,
          arrange_assistant: this.ruleForm.arrange_assistant,
          arrange_subject: this.ruleForm.arrange_subject,
          remark: this.ruleForm.remark,
          data: [],
        };
        var data = [];
        for (let index = 0; index < this.datalist.length; index++) {
          var class_arrival = 0;
          var class_type = 0;
          if (this.datalist[index].status == "到课") {
            class_arrival = 1;
          } else if (this.datalist[index].status == "请假") {
            class_arrival = 2;
          } else if (this.datalist[index].status == "旷课") {
            class_arrival = 3;
          }
          if (
            this.datalist[index].status == "到课" &&
            this.datalist[index].class_type
          ) {
            class_type = 1;
          } else {
            class_type = 0;
          }
          data.push({
            student_id: this.datalist[index].id,
            student_hours: this.datalist[index].valid_hours,
            id: 0,
            class_arrival: class_arrival,
            class_type: class_type,
          });
        }
        model.data = data;
        this.API.AttendClass(model).then((res) => {
          this.$message({
            showClose: true,
            message: res.data,
            type: "success",
          });
          this.$router.push({
            path: "/educationCenter/remembertoClass",
          });
        });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.Row_data.id) {
              this.add_attendClass();
            }
            else
              this.add_attendClassForNotArrange();
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .top_text {
    font-weight: bold;
    font-size: 18px;
  }

  .color-box {
    color: #0081ff;
    border: 1px solid #8cc5ff;
    background-color: #e8f5ff;
  }

  .configure {
    width: 100%;
    background-color: #fff;
    padding: 10px;
  }
</style>